import React, { useState } from "react";
import { SignIn } from "./util";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { TextInput, Button } from "evergreen-ui";
import hero from "../../assets/login/login-hero.svg";
import { color } from "../../themes/default.json";

const Login = () => {
  const [email, SetEmail] = useState("");
  const [password, SetPassword] = useState("");
  const history = useHistory();

  function handleSignIn() {
    try {
      const signInObject = {
        email: email,
        password: password,
      };
      let currentUser = null;
      SignIn(signInObject).then((res) => {
        if (res[0]) {
          console.log(res[0]);
          currentUser = {
            is_researcher: res[0].is_hiring ? true : false,
            uid: res[0].id,
            ...res[0],
          };
        }
        // Redirect researcher to posted opportunities, students to postings.
        if (currentUser) {
          currentUser.is_researcher
            ? history.push("/MyOpportunties")
            : history.push("/postings");
        }
      });
    } catch (error) {
      let errorMessage = error.message;
      console.log(errorMessage);
    }
  }

  function handleChangeEmail(e) {
    SetEmail(e.target.value);
  }

  function handleChangePassword(e) {
    SetPassword(e.target.value);
  }

  return (
    <div className="container h-vh-100">
      <div className="row h-100 align-items-center">
        <div className="col-md-6">
          <h2>Sign in to your account</h2>
          <h5>Making research easier one step at a time!</h5>
          <div className="mt-4 col-md-7 pl-0">
            <TextInput
              name="email"
              placeholder="Email"
              className="mt-2 w-100"
              value={email}
              onChange={handleChangeEmail}
            />
            <TextInput
              name="password"
              placeholder="Password"
              type="password"
              className="mt-2 w-100"
              value={password}
              onChange={handleChangePassword}
            />
            <div className="mt-3">
              <Link>
                <p>Forgot Password?</p>
              </Link>
            </div>
            <Button
              appearance="primary"
              className="w-100"
              onClick={handleSignIn}
              backgroundColor={color.primary}
              disabled={true}
            >
              Coming soon
            </Button>
            <p className="text-center mt-4">
              Don't have an account? Sign up{" "}
              <Link className="font-weight-bold" to="/register">
                here
              </Link>
              .
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <img src={hero} className="col" />
        </div>
      </div>
    </div>
  );
};
export default Login;
