/*
    Defines functions to handle data and time conversions
*/

const monthNamesLong = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const monthNamesShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// This function converts a date and time into the local time based on
//      browswer location through.
// INPUT: Date object
// OUTPUT: Date Object
// TODO: Currently the function only outputs the date object as inputed.
export function convertToLocal(date) {
  let dateObject = new Date(date); // Automatically converts UTC date to locale
  return dateObject;
}

/*
Converts a JS Date object to a SQL formatted Datetime object

Inputs
-----
  date : Date() object we wish to convert

Returns
------
  Datetime formatted string according to SQL format
*/

export function convertToSQLDatetime(date) {
  // Month starts at 0 so add +1
  return `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
}

// This function converts a date object into a friendly string
// INPUT: Date object
// OUTPUT: String
// Notes: Format might change. But update it here so it consistent
//        throughout the app.
export function friendlyTimeString(date) {
  let string = "";
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let month = date.getMonth();
  let dateNum = date.getDate();
  let year = date.getFullYear();
  let timezone = date
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")
    .slice(2)
    .join(" ");
  string = string + monthNamesShort[month] + " " + dateNum + " " + year + ", ";
  if (hours == 12) {
    // PM time
    string = string + hours + ":" + minutes + " PM ";
  } else if (date.getHours() >= 13) {
    // PM time
    hours -= 12;
    string = string + hours + ":" + minutes + " PM ";
  } else {
    string = string + hours + ":" + minutes + " AM ";
  }
  string += timezone;
  return string;
}

// This function converts a date and time into the UTC based on browswer
//      location through.
// INPUT: Date object
// OUTPUT: String
export function convertToUTC(date) {
  return date.toISOString();
}
