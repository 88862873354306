import React, { useState, useEffect } from "react";
import styles from "./Postings.module.css";
import {
  Badge,
  Button,
  ArrowRightIcon,
  Dialog,
  Textarea,
  FilePicker,
  Radio,
  Pane,
  toaster,
} from "evergreen-ui";
import { color } from "../../themes/default.json";
import { useGlobalState } from "../../App";
import { GetInvitations } from "../../requests/interview";
import { getOpportunity } from "../../requests/opportunity";
import {
  sendApplication,
  GetOpportunityApplication,
} from "../../requests/application";
import InterviewCard from "../Apply/InterviewCard";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const document_validation = {
  acceptedFormats: (files) =>
    ["application/pdf", "image/jpeg", "image/png"].includes(files[0]?.type) ||
    "Please upload a PDF or image",

  lessThan10MB: (files) => files[0]?.size < 10000000 || "Max 10MB",
};

export default function PostingPreview(props) {
  const [isShown, setIsShown] = useState(false);
  const [newResume, setNewResume] = useState("Upload new resume");

  // Declare state variables
  console.log("The given data is");
  console.log(props.data);
  const [opportunityId, setOpportunityId] = useState(props.data);
  const [cv] = useState("");
  const [coverLetter, uploadCoverLetter] = useState("");
  const [opportunity, setOpportunity] = useState("");
  const [ApplicationSent, setApplied] = useState(false);
  const [AlreadyApplied, setAlreadyApplied] = useState(false);
  const [InvitedForInterview, setInterviewInvited] = useState(false);
  const [Invitations, setInvitations] = useState([]);
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [state, _] = useGlobalState();

  const application = {
    OppId: props.data.id,
    UserId: state.user ? state.user.uid : null,
    Email: "amrmagdy.sharaf@gmail.com",
  };

  function OnApply() {
    console.log("CV here is ", getValues()["cv"]);
    const cv = getValues()["cv"][0];
    const coverLetter = getValues()["cl"][0];

    // NOTE : Temporarily storing files themselves in DB. Store names only after deployment
    application["CV"] = cv;
    application["CL"] = coverLetter;
    sendApplication(application).then((res) => {
      if (res.ok) {
        setApplied(true);
        toaster.success("Application Submitted!");
      } else {
        toaster.danger("Failed to Submit Application");
      }
    });
    setIsShown(false);
  }

  // Get details about the current opportunity with ID.
  useEffect(() => {
    getOpportunity(application.OppId).then((res) => {
      setOpportunity(res[0]);
    });

    GetOpportunityApplication(application.UserId, application.OppId).then(
      (res) => {
        setAlreadyApplied(res.length > 0);
        console.log(`Already Applied ${AlreadyApplied}`);
      }
    );

    // Get user invitations (proposed times) for an interview.
    GetInvitations(application.OppId, application.UserId).then((res) => {
      setInterviewInvited(res.length > 0);
      setInvitations(res);
      console.log(`Invited for interview ${InvitedForInterview}`);
    });
    register("cv", {
      required: {
        value: true,
        message: "Warning: Resume missing",
      },
      validate: document_validation,
    });

    register("cl", {
      required: {
        value: true,
        message: "Warning: Cover Letter missing",
      },
      validate: document_validation,
    });
  }, []);

  const ViewInterviewProposals = () => {
    return (
      <main>
        {Invitations &&
          Invitations.map((invitation) => (
            <InterviewCard invitation={invitation} />
          ))}
      </main>
    );
  };

  const ViewApplicationStatus = () => {
    return (
      <React.Fragment>
        {!InvitedForInterview && (
          <h4>
            You have already applied to this opportunity or accepted an
            interview time :)
          </h4>
        )}
        {InvitedForInterview && <ViewInterviewProposals />}
      </React.Fragment>
    );
  };

  return (
    <>
      {/* Application Window */}
      <Dialog
        isShown={isShown}
        title={props.data.position_name}
        onConfirm={handleSubmit(OnApply)}
        onCloseComplete={() => setIsShown(false)}
        confirmLabel="Apply"
        hasFooter={!AlreadyApplied}
        preventBodyScrolling
      >
        {AlreadyApplied ? (
          <ViewApplicationStatus />
        ) : (
          <>
            <h5>Resume</h5>
            <Pane aria-label="Radio Group Label 12" role="group">
              <Radio
                checked={newResume === "Use default resume"}
                disabled
                label="Use default resume (Coming Soon)"
                value="Use default resume"
                onChange={(event) => setNewResume(event.target.value)}
              />
              <Radio
                checked={newResume === "Upload new resume"}
                label="Upload new resume"
                value="Upload new resume"
                onChange={(event) => setNewResume(event.target.value)}
              />
            </Pane>
            {newResume === "Upload new resume" && (
              <>
                <FilePicker
                  width={250}
                  name="CV"
                  onChange={(file) =>
                    setValue("cv", file, { shouldValidate: true }) &&
                    console.log(getValues())
                  }
                  placeholder="Select new resume"
                />
                <ErrorMessage
                  errors={errors}
                  name="cv"
                  render={({ message }) => (
                    <p style={{ color: "red" }}>{message}</p>
                  )}
                />
              </>
            )}
            <h5 className="mt-4">Cover Letter</h5>
            <FilePicker
              width={250}
              name="CoverLetter"
              placeholder="Select cover letter"
              onChange={(file) =>
                setValue("cl", file, { shouldValidate: true })
              }
            />
            <ErrorMessage
              errors={errors}
              name="cl"
              render={({ message }) => (
                <p style={{ color: "red" }}>{message}</p>
              )}
            />
            <h5 className="mt-4">
              Send a message to the researcher (optional)
            </h5>
            <Textarea
              name="messageToResearcher"
              placeholder="Hey there! I am very excited to be applying..."
            />
          </>
        )}
      </Dialog>

      {/* Posting Card */}
      <div className={styles.previewCard}>
        <div className="py-5 px-5">
          {props.data.is_fulltime === 1 ? (
            <Badge color="blue" className="mb-3">
              Full Time
            </Badge>
          ) : (
            <Badge color="orange" className="mb-3">
              Part Time
            </Badge>
          )}
          <h3>{props.data.position_name}</h3>
          <p className="color-light">
            {props.data.affiliation} | {props.data.city}
          </p>
          <p>{props.data.position_description}</p>
          <p className="font-weight-bold">Duties and Responsibilities</p>
          {/* <ul>
            {props.data.duties.map((duty) => {
              return <li>{duty}</li>;
            })}
          </ul> */}
          <Button
            appearance="primary"
            iconAfter={ArrowRightIcon}
            backgroundColor={color.primary}
            className="mt-3"
            onClick={() => setIsShown(true)}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  );
}

export function OpportunityPreview(props) {
  return (
    <>
      {/* Posting Card */}
      <div className={styles.previewCard}>
        <div className="py-5 px-5">
          {props.data.is_fulltime === 1 ? (
            <Badge color="blue" className="mb-3">
              Full Time
            </Badge>
          ) : (
            <Badge color="orange" className="mb-3">
              Part Time
            </Badge>
          )}
          <h3>{props.data.position_name}</h3>
          <p className="color-light">
            {props.data.institution} | {props.data.city}
          </p>
          <p>{props.data.position_description}</p>
          <p className="font-weight-bold">Duties and Responsibilities:</p>
          {props.data.position_responsibilities.split("\n").map((line) => (
            <p>{line}</p>
          ))}
          <p className="font-weight-bold">Qualifications:</p>
          <p>{props.data.position_qualification}</p>
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Pane>
              <Button
                appearance="primary"
                iconAfter={ArrowRightIcon}
                backgroundColor={color.primary}
                onClick={props.onViewApplications}
              >
                View Applicants
              </Button>
              <Button disabled={true} marginLeft={8}>
                Edit
              </Button>
            </Pane>
            <Button disabled={true}>Archive</Button>
          </Pane>
        </div>
      </div>
    </>
  );
}
