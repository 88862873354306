const VALIDATE = {};

//Custom email validation
VALIDATE.EMAIL =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

//At least one number and one special character
VALIDATE.PASSWORD = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

//Non-empty affiliation name
VALIDATE.AFFILIATION = /^(?!\s*$).+/;

VALIDATE.TEXTONLY = /^([^0-9]*)$/;

export default VALIDATE;
