import React, { useState, useEffect } from "react";
import styles from "./Postings.module.css";
import { color } from "../../themes/default.json";
import PostingThumbnail from "./PostingThumbnail";
import PostingPreview from "./PostingPreview";
import { useRouteMatch, useLocation } from "react-router-dom";

import { SearchInput, Button, ArrowRightIcon, SelectMenu } from "evergreen-ui";

import {
  getOpportunity,
  GetOpportunitiesList,
} from "../../requests/opportunity";

const POSITION_TYPES = {
  ANY: "Any",
  PART_TIME: "Part-time",
  FULL_TIME: "Full-time",
};

const Postings = () => {
  const [opportunities, SetOpportunities] = useState([]);
  const [currentOpportunity, SetCurrentOpportunity] = useState(null);
  const [positionName, SetPositionName] = useState("");
  const [positionType, SetPositionType] = useState(null);
  const [searchFilters, SetSearchFilters] = useState({});
  let { path } = useRouteMatch();
  let match = useRouteMatch(`${path}/:id`);

  function SetSearchParams() {
    // TODO: Add validation to search params.
    let filters_object = { position_name: positionName };
    if (positionType == "Full-time") {
      filters_object["is_fulltime"] = true;
    } else if (positionType == "Part-time") {
      filters_object["is_fulltime"] = false;
    }
    SetSearchFilters(filters_object);
  }

  // Selected Opportunity handler
  useEffect(() => {
    async function getCurrentOpportunity() {
      if (match) {
        let result = await getOpportunity(match.params.id);
        if ((await result.length) > 0) {
          SetCurrentOpportunity(await result[0]);
        } else {
          SetCurrentOpportunity(null);
        }
      } else {
        SetCurrentOpportunity(null);
      }
      console.log(currentOpportunity);
    }
    getCurrentOpportunity();
  }, [match ? match.params.id : null]);

  useEffect(() => {
    GetOpportunitiesList(searchFilters).then((res) => {
      const parsedRes = res.map((x) => {
        let y = {
          type: x.is_fulltime == 1 ? "Fulltime" : "Part-time",
          ...x,
        };
        return y;
      });
      console.log(parsedRes);
      SetOpportunities(parsedRes);
    });
  }, [searchFilters]);

  return (
    <div className="container margin-t-large">
      <h2>Welcome back, Dwarkesh</h2>
      <div className="row mt-4 justify-content-between">
        <div className="col-md-6">
          <SearchInput
            placeholder="Position"
            onChange={(e) => SetPositionName(e.target.value)}
            width="100%"
          />
        </div>
        <div>
          <SelectMenu
            title="Any"
            options={Object.values(POSITION_TYPES).map((label) => ({
              label,
              value: label,
            }))}
            selected={positionType}
            hasFilter={false}
            hasTitle={false}
            onSelect={(item) => SetPositionType(item.value)}
          >
            <Button>{positionType || "Select position type"}</Button>
          </SelectMenu>
        </div>
        {/* <div className="col-md-3">
          <SearchInput placeholder="Location" width="100%" />
        </div> */}
        {/* <Button className="mr-3" appearance="minimal" color={color.primary}>
          Advanced Search
        </Button> */}
        <div className="col-auto">
          <Button
            appearance="primary"
            iconAfter={ArrowRightIcon}
            backgroundColor={color.primary}
            onClick={() => SetSearchParams()}
          >
            Search
          </Button>
        </div>
      </div>
      {/* <div className="mt-3">
        <Button className="mr-3" iconAfter={CaretDownIcon}>
          Date Posted
        </Button>
        <Button className="mr-3" iconAfter={CaretDownIcon}>
          Remote
        </Button>
        <Button className="mr-3" iconAfter={CaretDownIcon}>
          Within 25 kilometers
        </Button>
        <Button className="mr-3" iconAfter={CaretDownIcon}>
          Institution
        </Button>
        <Button className="mr-3" iconAfter={CaretDownIcon}>
          Discipline
        </Button>
      </div> */}
      <div className="row justify-content-between mt-5">
        <div className="col-md-6">
          {opportunities.map((posting) => {
            return <PostingThumbnail data={posting} />;
          })}
        </div>
        <div className="col-md-6">
          {currentOpportunity !== null ? (
            <PostingPreview data={currentOpportunity} />
          ) : (
            <h5 className="text-center mt-5">
              Select a posting to view more details!
            </h5>
          )}
        </div>
      </div>
    </div>
  );
};

export default Postings;
