/*
Defines methods for fetching and inserting skills
*/

const ROUTE = "/api/skills";

export function GetSkills(id) {
  let skills = fetch(`${ROUTE}/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      return response.json();
    }
  });
  return skills;
}

export function CreateSkillsEntry(newEntry) {
  return fetch(ROUTE, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      content: newEntry,
    }),
  });
}
