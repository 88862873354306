import React from "react";
import icon from "../../assets/profileIcon.png";
import CONSTANTS from "../../constants";
import { withRouter, Link } from "react-router-dom";
import { GetUser } from "../../requests/user";
import { GetInstitution } from "../../requests/institution";
import { GetPostingCount } from "../../requests/opportunity";

class User extends React.Component {
  constructor(props) {
    super(props);
    //Default field values
    this.state = {
      tempFirstName: "",
      tempLastName: "",
      tempUni: "",
      name: "Blank",
      uni: "Blank",
      host_id: "",
      applied: 0,
      responses: 0,
      major: "",
      phone: "",
      email: "",
      posted: 0,
    };
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleUniChange = this.handleUniChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.getOpportunties = this.getOpportunties.bind(this);
    this.SetResearcher = this.SetResearcher.bind(this);
    this.SetPostingResponsesCount = this.SetPostingResponsesCount.bind(this);
  }

  getOpportunties() {
    let usersList = fetch(CONSTANTS.ENDPOINT.LIST).then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    });
    return usersList;
  }

  SetResearcher(id) {
    console.log("Reached Set Researcher");
    var institution = 0;
    GetUser(id).then((res) => {
      console.log(res);
      this.setState({
        host_id: res[0].id,
        name: res[0].name,
        tempFirstName: res[0].name.split(" ")[0],
        tempLastName: res[0].name.split(" ")[1],
        uni: res[0].affiliation,
        tempUni: res[0].tempUni,
      });

      GetInstitution(res[0].affiliation).then((res) => {
        console.log(res);
        console.log(institution);
        this.setState({
          uni: res[0].name,
          tempUni: res[0].name,
        });
      });
    });
  }

  SetPostingResponsesCount(id) {
    GetPostingCount(id).then((res) => {
      console.log("Responses count result " + res);
      this.setState({
        posted: res[0].num_postings,
      });
    });
  }

  componentDidMount() {
    this.SetResearcher(this.props.match.params["id"]);
    //this.SetPostingResponsesCount(this.props.match.params["id"]);
  }

  handleFirstNameChange(e) {
    this.setState({
      tempFirstName: e.target.value,
    });
  }

  handleLastNameChange(e) {
    this.setState({
      tempLastName: e.target.value,
    });
  }

  handleUniChange(e) {
    this.setState({
      tempUni: e.target.value,
    });
  }

  handleUpdate() {
    this.setState({
      name: this.state.tempFirstName + " " + this.state.tempLastName,
      uni: this.state.tempUni,
    });
  }

  render() {
    return (
      <div className="container pt-5">
        <div
          style={{ boxShadow: "0.1px 0.5px 2px 3px #9E9E9E" }}
          className="profileCard mt-2 mr-3 solid "
        >
          <div className="photoSection">
            <img className="ml-4" style={{ width: "100px" }} src={icon}></img>
            <h5 ref={this.nameRef}>{this.state.name}</h5>
            <p style={{ color: "gray" }} ref={this.uniRef}>
              {this.state.uni}
            </p>
          </div>

          <div className="opportuntiesInfo ml-3">
            <div className="justify-content-left row">
              <Link
                className="mr-2"
                to={{
                  pathname: `${this.props.location.pathname}/MyOpportunties`,
                  state: {
                    hostName: this.state.name,
                    host_id: this.state.host_id,
                  },
                }}
              >
                Opportunties Posted
              </Link>
              <p style={{ color: "orange" }}>{this.state.applied}</p>
            </div>

            <div className="justify-content-left row">
              <p className="mr-2">Responses</p>
              <p style={{ color: "green" }}>{this.state.responses}</p>
            </div>
          </div>
        </div>

        <div className="personalInfo">
          <div className="infoHeader mb-3">
            <h6>Account Information</h6>
          </div>

          <div className="inputsSection ml-3">
            <div className="row">
              <div className="column">
                <h6>First Name</h6>
                <input
                  ref={this.firstNameRef}
                  onChange={this.handleFirstNameChange}
                  className="inputBox"
                  placeholder="Winston"
                  defaultValue={this.state.tempFirstName}
                ></input>
              </div>
              <div className="column">
                <h6>Last Name</h6>
                <input
                  ref={this.lastNameRef}
                  onChange={this.handleLastNameChange}
                  className="inputBox"
                  placeholder="Churchill"
                  defaultValue={this.state.tempLastName}
                ></input>
              </div>
            </div>

            <div className="row">
              <div className="column">
                <h6>Phone</h6>
                <input
                  className="inputBox"
                  placeholder="000-000-0000"
                  defaultValue={this.state.phone}
                ></input>
              </div>
              <div className="column">
                <h6>Email</h6>
                <input
                  className="inputBox"
                  placeholder="winston@gmail.com"
                  defaultValue={this.state.email}
                ></input>
              </div>
            </div>

            <div className="row">
              <div className="column">
                <h6>Field of Study</h6>
                <input
                  className="inputBox"
                  placeholder="Computer Science"
                  defaultValue={this.state.major}
                ></input>
              </div>
              <div className="column">
                <h6>University</h6>
                <input
                  className="inputBox"
                  onChange={this.handleUniChange}
                  placeholder="XXXXX"
                  defaultValue={this.state.tempUni}
                ></input>
              </div>
            </div>
          </div>

          <div>
            <button className="updateButton mr-3" onClick={this.handleUpdate}>
              <p className="mt-1" style={{ color: "#ffffff" }}>
                Update
              </p>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(User);
