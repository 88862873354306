import React, { useState, useEffect } from "react";
import styles from "./apply.module.css";
import { getOpportunity } from "../../requests/opportunity";
import {
  sendApplication,
  GetOpportunityApplication,
} from "../../requests/application";
import { GetInvitations } from "../../requests/interview";
import InterviewCard from "./InterviewCard";
import { useGlobalState } from "../../App";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

/*
props = {
    user: UserObject,
    opportunity: OpportunityObject
}
*/

const document_validation = {
  acceptedFormats: (files) =>
    ["application/pdf", "image/jpeg", "image/png"].includes(files[0]?.type) ||
    "Please upload a PDF or image",

  lessThan10MB: (files) => files[0]?.size < 10000000 || "Max 10MB",
};

function Apply(props) {
  // Declare prop variables
  // const [opportunity, user]  = props;

  // Declare state variables
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [cv, uploadCV] = useState("");
  const [coverLetter, uploadCoverLetter] = useState("");
  const [opportunity, setOpportunity] = useState("");
  const [ApplicationSent, setApplied] = useState(false);
  const [AlreadyApplied, setAlreadyApplied] = useState(false);
  const [InvitedForInterview, setInterviewInvited] = useState(false);
  const [Invitations, setInvitations] = useState([]);
  const [state, _] = useGlobalState();

  const application = {
    oppID: 2,
    user: state.user ? state.user.uid : null,
    email: state.user ? state.user.email : null,
  };
  function OnApply(_) {
    const cv = getValues()["cv"][0];
    const coverLetter = getValues()["cl"][0];

    // NOTE : Temporarily storing files themselves in DB. Store names only after deployment
    application["CV"] = cv;
    application["CL"] = coverLetter;
    sendApplication(application).then((res) => {
      if (res.ok) {
        setApplied(true);
      }
    });
  }

  // Get details about the current opportunity with ID.
  useEffect(() => {
    getOpportunity(application.oppID).then((res) => {
      setOpportunity(res[0]);
    });

    GetOpportunityApplication(application.user, application.oppID).then(
      (res) => {
        setAlreadyApplied(res.length > 0);
        console.log(`Already Applied ${AlreadyApplied}`);
      }
    );

    // Get user invitations (proposed times) for an interview.
    GetInvitations(application.oppID, application.user).then((res) => {
      setInterviewInvited(res.length > 0);
      setInvitations(res);
      console.log(`Invited for interview ${InvitedForInterview}`);
    });
  }, []);

  const ViewInterviewProposals = () => {
    return (
      <main>
        {Invitations &&
          Invitations.map((invitation) => (
            <InterviewCard invitation={invitation} />
          ))}
      </main>
    );
  };

  const ViewApplicationStatus = () => {
    return (
      <React.Fragment>
        {!InvitedForInterview && (
          <h4>
            You have already applied to this opportunity or accepted an
            interview time :)
          </h4>
        )}
        {InvitedForInterview && <ViewInterviewProposals />}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {AlreadyApplied && <ViewApplicationStatus />}
      {!AlreadyApplied && (
        <form onSubmit={handleSubmit(OnApply)}>
          <div
            style={{
              textAlign: "center",
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
            }}
          >
            <a>OpportunityId: {application.oppID}</a>
            <h4 className={styles.label}>CV:</h4>
            {opportunity && opportunity["cv_required"] ? (
              <input
                type="file"
                name="CV"
                {...register("cv", {
                  required: {
                    value: true,
                    message: "Warning: Resume missing",
                  },
                  validate: document_validation,
                })}
              />
            ) : (
              <h4>N/A</h4>
            )}
            <ErrorMessage
              errors={errors}
              name="cv"
              render={({ message }) => (
                <p style={{ color: "red" }}>{message}</p>
              )}
            />

            <h4 className={styles.label}>Cover Letter:</h4>
            {opportunity && opportunity["cl_required"] ? (
              <input
                type="file"
                name="CoverLetter"
                {...register("cl", {
                  required: {
                    value: true,
                    message: "Warning: Cover Letter missing",
                  },
                  validate: document_validation,
                })}
              />
            ) : (
              <h4>N/A</h4>
            )}
            <ErrorMessage
              errors={errors}
              name="cl"
              render={({ message }) => (
                <p style={{ color: "red" }}>{message}</p>
              )}
            />
          </div>

          <button
            type="submit"
            style={{ backgroundColor: ApplicationSent ? "#000000" : "#025fce" }}
            disabled={ApplicationSent == true ? "disabled" : null}
          >
            Apply
          </button>
        </form>
      )}
    </React.Fragment>
  );
}

export default Apply;
