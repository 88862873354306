import React from "react";
import OpportunitiesTable from "../OpportunitiesTable";

const MyOpportunties = () => {
  return (
    <div>
      <div style={{ height: 400, width: "100%" }}>
        <OpportunitiesTable />
      </div>
    </div>
  );
};

export default MyOpportunties;
