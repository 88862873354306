/*
Defines methods for handling applications. All fetches inserting/updating
/reading applications as well as sending confirmations should be defined here.
*/

export const ROUTE = "/api/application";

export function sendApplication(application) {
  return fetch(ROUTE, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      content: application,
    }),
  });
}

// Retrieve Applicants for a given opportunity.
export function GetApplications(id) {
  return fetch(`${ROUTE}/opportunity/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      let result = response.json();
      console.log(result);
      return result;
    }
  });
}

export function GetOpportunityApplication(user, opportunity) {
  //Return the user's application to a given opportunity (if one exists)
  return fetch(`${ROUTE}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      user: `${user}`,
      opportunity: `${opportunity}`,
    },
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      return response.json();
    }
  });
}

export function AcceptApplication(app_id, opp_id) {
  return fetch(`${ROUTE}/AcceptApplication`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      app_id: app_id,
      opp_id: opp_id,
    }),
  });
}

export function RejectApplication(app_id, email) {
  return fetch(`${ROUTE}/RejectApplication`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      app_id: app_id,
      email: email,
    }),
  });
}
