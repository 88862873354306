import React, { useEffect, useState } from "react";
import { Pane } from "evergreen-ui";
import { OpportunityThumbnail } from "../Postings/PostingThumbnail";
import { OpportunityPreview } from "../Postings/PostingPreview";
import { GetPostedOpportunties } from "../../requests/opportunity";
import { Redirect, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { useGlobalState } from "../../App";
import Opportunities from "../OpportunitiesOld";

function OpportunitiesTable() {
  const [opportunities, SetOpportunities] = useState([]);
  const [currentOpportunity, SetCurrentOpportunity] = useState(null);
  const [viewApplications, setViewApplications] = useState(false);
  const [state, _] = useGlobalState();

  useEffect(() => {
    if (state?.user?.uid) {
      const host_id = state.user.uid;
      GetPostedOpportunties(host_id).then((res) => {
        SetOpportunities(res);
      });
    }
  }, [state?.user?.uid]);

  // user not logged in - redirect to signin
  if (!state?.user?.uid) {
    return <Redirect to="/login" />;
  }

  if (viewApplications) {
    return (
      <Opportunities
        onGoBack={() => setViewApplications(false)}
        opportunityId={viewApplications}
      />
    );
  }
  return (
    <div className="container margin-t-large">
      <h2>Welcome back, Dr. Amin</h2>
      <div className="row justify-content-between mt-5">
        <div className="col-md-6">
          {opportunities?.map((posting) => {
            return (
              <Pane
                onClick={() => {
                  SetCurrentOpportunity(posting);
                  // RedirectOnClick(idx + 1);
                }}
              >
                <OpportunityThumbnail data={posting} />
              </Pane>
            );
          })}
          {!opportunities?.length && (
            <>
              <Link to="/CreateOpportunities">
                <p>Create Your First Opportunity Now!</p>
              </Link>
            </>
          )}
        </div>
        <div className="col-md-6">
          {currentOpportunity !== null && (
            <OpportunityPreview
              data={currentOpportunity}
              onViewApplications={() =>
                setViewApplications(currentOpportunity.id)
              }
            />
          )}
        </div>
      </div>
    </div>
    // <div>
    //   <div style={{ height: 400, width: "100%" }}>
    //     <DisplayPostings />
    //   </div>
    // </div>
  );
}

export default withRouter(OpportunitiesTable);
