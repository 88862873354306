import React, { useState, useEffect } from "react";
import {
  Avatar,
  Pane,
  Table,
  Badge,
  Button,
  TickIcon,
  DisableIcon,
  ArrowTopRightIcon,
  StatusIndicator,
} from "evergreen-ui";
import { IconText } from "../Common/IconText";
import styles from "./profile.module.css";
import { Link } from "react-router-dom";
import { GetStudentProposedTimes } from "../../requests/interview";
import { useGlobalState } from "../../App";
import ProposedTimes from "./ProposedTimes";
import { friendlyTimeString } from "../../services/time";

export default function Profile() {
  const [Interviews, SetInterviews] = useState([]);
  const [state, dispatch] = useGlobalState();

  useEffect(() => {
    GetStudentProposedTimes(state.user.uid).then((res) => {
      if (res?.length > 0 && Interviews?.length == 0) {
        SetInterviews(res);
      }
    });
  }, [Interviews]);

  return (
    <main className="container margin-t-large">
      <div className="row">
        <div className="col-md-3">
          <div className="row align-items-center">
            <Avatar name="Dwarkesh Amin" size={40} marginRight={16} />
            <div>
              <h5 className={styles.name}>Dwarkesh Amin</h5>
              <p className={styles.email}>amin.dwarkesh@gmail.com</p>
            </div>
          </div>
        </div>
        <div className="col-md">
          <h2>Applications</h2>
          <hr />
          <h5 className={styles.headertext}>All Active Interview Times</h5>
          <p className={styles.subtext}>
            Accept, reject, or reschedule interview times for your current
            applications.
          </p>
          <ProposedTimes Interviews={Interviews} />
          <div className="mt-5">
            <h5 className={styles.headertext}>All Active Applications</h5>
            <p className={styles.subtext}>
              View all of your current applications
            </p>
            <Pane marginTop={32}>
              <Table>
                <Table.Head>
                  <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>
                    Researcher
                  </Table.TextCell>
                  <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>
                    Discipline
                  </Table.TextCell>
                  <Table.TextCell>Posting</Table.TextCell>
                  <Table.TextCell>Status</Table.TextCell>
                  <Table.TextCell>Actions</Table.TextCell>
                </Table.Head>
                <Table.Body>
                  <Table.Row>
                    <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>
                      <div className="m-0 row align-items-center">
                        <Avatar
                          name="Dwarkesh Amin"
                          size={30}
                          marginRight={12}
                        />
                        Dwarkesh Amin
                      </div>
                    </Table.TextCell>
                    <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>
                      <Badge color="neutral">Molecular Biology</Badge>
                    </Table.TextCell>
                    <Table.TextCell>
                      <Link to="#">
                        <IconText
                          iconBefore={ArrowTopRightIcon}
                          color="#3366FF"
                        >
                          View Posting
                        </IconText>
                      </Link>
                    </Table.TextCell>
                    <Table.TextCell>
                      <StatusIndicator color="warning">Applied</StatusIndicator>
                    </Table.TextCell>
                    <Table.TextCell>
                      <Button>Archive</Button>
                    </Table.TextCell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Pane>
          </div>
        </div>
      </div>
    </main>
  );
}
