import { useGlobalState } from "../App";
import { Route, Redirect } from "react-router-dom";
import { CONDITIONS } from "./private_route_conditions";
import { ReloadUser } from "../auth";
import React, { useState, useEffect } from "react";
import { Spinner } from "evergreen-ui";

// Gating that ensures every user type accesses the corresponding
// routes.
// To add a role:
// 1 - define role in private_route_conditions.js
// 2- Add the role-specific logic in the switch statement below before default.
const PrivateRoute = (props) => {
  const [state, dispatch] = useGlobalState();

  ReloadUser(state, dispatch);

  // console.log("Global State : ", state.user);
  console.log("Role : ", props.role);
  const role = props.role;
  let condition = null;
  switch (role) {
    case CONDITIONS.IS_NOT_SIGNED_IN:
      condition = !state || !state.is_logged_in;
      break;
    case CONDITIONS.IS_RESEARCHER:
      condition = state.user && state.user.is_researcher;
      break;
    case CONDITIONS.IS_SIGNED_IN:
      condition = state.is_logged_in;
      break;
    case CONDITIONS.IS_STUDENT:
      condition = state.user && !state.user.is_researcher;
      break;
    default:
      condition = null;
  }
  return state.is_initialized ? (
    condition ? (
      <Route path={props.path} component={props.component} />
    ) : (
      <Redirect to="/home" />
    )
  ) : (
    <Spinner delay={1} size={40} />
  );
};

export default PrivateRoute;
