import React, { useEffect, useState } from "react";
import {
  Button,
  ArrowRightIcon,
  Text,
  Strong,
  Popover,
  Pane,
  Alert,
  Avatar,
} from "evergreen-ui";
import { Link } from "react-router-dom";
import styles from "./navbar.module.css";
import { useGlobalState } from "../../App";
import { color } from "../../themes/default.json";
import { GetUser } from "../../requests/user";

//TODO Web Template Studio: Add a new link in the NavBar for your page here.
// A skip link is included as an accessibility best practice. For more information visit https://www.w3.org/WAI/WCAG21/Techniques/general/G1.
const NavBar = (props) => {
  // Subscribe to user state changes.
  const [state, _] = useGlobalState();
  const [name, setName] = useState();
  const [hostId, setHostId] = useState();

  useEffect(() => {
    SetResearcher(state?.user?.uid);
  });

  const SetResearcher = (id) => {
    GetUser(id).then((res) => {
      if (res?.length) {
        setHostId(res[0].id);
        setName(res[0].name);
      }
    });
  };
  return (
    <React.Fragment>
      <div className={styles.skipLink}>
        <a href="#mainContent">Skip to Main Content</a>
      </div>
      <nav className="navbar navbar-expand-sm navbar-light px-5 justify-content-between fixed-top">
        <Link className="navbar-brand" to="/" role="heading" aria-level="1">
          <Strong size={16} color={color.textSecondary}>
            Academia
          </Strong>
          <Text size={16} color={color.blue}>
            Match
          </Text>
        </Link>
        <div className="navbar-nav">
          {state.user && (
            <>
              {!state.user.is_researcher && (
                <Link className="nav-item nav-link active" to="/postings">
                  <Button appearance="minimal" color={color.primary}>
                    Postings
                  </Button>
                </Link>
              )}
              {state.user.is_researcher ? (
                <>
                  <Link
                    className="nav-item nav-link active"
                    to={{ pathname: "/MyOpportunties" }}
                  >
                    <Button appearance="minimal" color={color.primary}>
                      My Opportunities
                    </Button>
                  </Link>
                  <Link
                    className="nav-item nav-link active"
                    to="/CreateOpportunities"
                  >
                    <Button appearance="minimal" color={color.primary}>
                      Create Opportunities
                    </Button>
                  </Link>
                </>
              ) : (
                <>
                  <Link className="nav-item nav-link active" to={`/profile`}>
                    <Button appearance="minimal" color={color.primary}>
                      Profile
                    </Button>
                  </Link>
                </>
              )}
              <Popover
                content={
                  <Pane
                    width={240}
                    height={240} /*
                    display="flex"  
                    alignItems="center"
                    justifyContent="center" */
                    flexDirection="column"
                  >
                    <div className="m-4">
                      <h3>{state?.user?.name}</h3>
                      <p>
                        {state?.user?.is_researcher ? "Researcher" : "Student"}
                      </p>
                      <Link to="/signout">
                        <Button
                          appearance="primary"
                          iconAfter={ArrowRightIcon}
                          backgroundColor={color.primary}
                          className="fixed-bottom m-4"
                        >
                          Log out
                        </Button>
                      </Link>
                    </div>
                  </Pane>
                }
              >
                <Avatar
                  name={state?.user?.name}
                  size={40}
                  className="mt-1 ml-2"
                />
              </Popover>
            </>
          )}
          {!state.user && (
            <Link className="nav-item nav-link active pr-0" to="/login">
              <Button
                appearance="primary"
                iconAfter={ArrowRightIcon}
                backgroundColor={color.primary}
              >
                Login
              </Button>
            </Link>
          )}
        </div>
        {/* {state && state.user && !state.is_verified && (
          <Alert
            intent="none"
            title={
              "Your account is not verified. This may limit the features you can use on the app.\
              If you have already verified your email, please refresh this page. Or click here to receive another."
            }
          />
        )} */}
      </nav>
    </React.Fragment>
  );
};
export default NavBar;
