import React from "react";
import styles from "./footer.module.css";
import { color } from "../../themes/default.json";
import { Link, Strong, Text } from "evergreen-ui";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <hr />
        <Link className="navbar-brand" to="/" role="heading" aria-level="1">
          <Strong size={16} color={color.textSecondary}>
            Academia
          </Strong>
          <Text size={16} color={color.blue}>
            Match
          </Text>
        </Link>
        <p className={styles.smallText}>
          <span className={styles.smallText}>Developed by AcademiaMatch</span>{" "}
          <span className={styles.smallText}>|</span>{" "}
          <Link className={styles.smallText}>Contact</Link>{" "}
          <Link className={styles.smallText}>Privacy</Link>{" "}
          <Link className={styles.smallText}>Terms and Conditions</Link>
        </p>
      </div>
    </footer>
  );
};
export default Footer;
