import React, { useState, useEffect } from "react";
import styles from "./Register.module.css";
import { SignUp } from "./util";
import {
  TextInput,
  Button,
  ArrowRightIcon,
  TagInput,
  ArrowLeftIcon,
  Select,
  toaster,
} from "evergreen-ui";
import { useHistory } from "react-router-dom";
import { color } from "../../themes/default.json";
import hero from "../../assets/login/login-hero.svg";
import { useForm } from "react-hook-form";
import VALIDATE from "./RegisterValidation";
import { ErrorMessage } from "@hookform/error-message";
import {
  GetAllowedAffiliations,
  IsValidEmailAffiliationPair,
} from "../../services/affiliations";

const MAX_ATTRIBUTES = 10;

const Register = () => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [formData, SetFormData] = useState({});
  const [interestsArray, SetInterestsArray] = useState([]);
  const [skillsArray, SetSkillsArray] = useState([]);
  const [userTypeSelected, setUserTypeSelected] = useState(false);
  const [loading, SetLoading] = useState(false);
  const [universities, SetUniversities] = useState([]);
  const history = useHistory();

  useEffect(() => {
    SetUniversities(GetAllowedAffiliations());
  }, []);

  function handleSignUp() {
    try {
      const signUpObject = getValues();

      // Validate affiliation and email domain pair.
      if (
        !IsValidEmailAffiliationPair(
          signUpObject["affiliation"],
          signUpObject["email"]
        )
      ) {
        toaster.danger(
          "Your email domain does not match your affiliation's domain.\
         Please input your affiliation's email address for verification."
        );
        return;
      }

      SetLoading(true);
      signUpObject["is_researcher"] = formData.accountType === "researcher";
      signUpObject["interests"] = interestsArray.join(",");
      signUpObject["skills"] = skillsArray.join(",");

      SignUp(signUpObject).then((res) => {
        // redirect to /home on successful register.
        if (signUpObject.is_researcher) {
          console.log("Sign up object : ", signUpObject);
          history.push("/CreateOpportunities");
        } else {
          history.push("/home");
        }
      });
    } catch (error) {
      SetLoading(false);
      console.log(error.message);
    }
  }

  function handleChange(e) {
    const value = e.target.value;
    SetFormData({
      ...formData,
      [e.target.name]: value,
    });
  }

  function handleTagSubmit(values, SetValues) {
    let uniqueValues = [...new Set(values)];
    let valuesLen = uniqueValues.length;
    if (valuesLen > MAX_ATTRIBUTES) return;
    let valuesCharLen = uniqueValues.join(",").length;
    if (valuesCharLen >= 255) return;

    SetValues(uniqueValues);
  }
  const RadioCards = () => {
    return (
      <>
        <div className="row" name="account-types">
          <label className="col-md-6 d-block m-0">
            <input
              type="radio"
              className={styles.inputElement}
              value="student"
              name="accountType"
              checked={formData.accountType === "student"}
              onChange={handleChange}
            />
            <div className={styles.cardDiv + " col-md-12 text-center py-5"}>
              <h5 className={styles.cardText}>Student</h5>
            </div>
          </label>
          <label className="col-md-6 d-block m-0">
            <input
              type="radio"
              name="accountType"
              className={styles.inputElement}
              value="researcher"
              checked={formData.accountType === "researcher"}
              onChange={handleChange}
            />
            <div className={styles.cardDiv + " col-md-12 text-center py-5"}>
              <h5 className={styles.cardText}>Researcher</h5>
            </div>
          </label>
        </div>
      </>
    );
  };

  return (
    <div className="container margin-t-large">
      <div className="row h-100 align-items-top">
        <div className="col-md-6">
          <h2>Register for AcademiaMatch</h2>
          <h5>One small step for you, one giant leap for science!</h5>
          {!userTypeSelected && (
            <div className="mt-4">
              <h5 className="font-weight-bold">
                Are you a student or researcher?
              </h5>
              <div className="row mt-4">
                <div className="col-md-7">
                  <RadioCards />
                </div>
              </div>
              <Button
                appearance="primary"
                disabled={!formData.accountType}
                iconAfter={ArrowRightIcon}
                backgroundColor={color.primary}
                onClick={() => setUserTypeSelected(true)}
                className="mt-4"
              >
                Continue
              </Button>
            </div>
          )}
          {userTypeSelected && (
            <div className="mt-4">
              <form onSubmit={handleSubmit(handleSignUp)}>
                <div className="mt-4 col-md-7 pl-0">
                  <TextInput
                    name="name"
                    placeholder="Name"
                    type="text"
                    className="mt-2 w-100"
                    {...register("name", {
                      required: {
                        value: true,
                        message: "You must enter your name",
                      },
                      pattern: {
                        value: VALIDATE.TEXTONLY,
                        message: "Name cannot contain non-alphabetical input",
                      },
                    })}
                  />

                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => (
                      <p style={{ color: "red" }}>{message}</p>
                    )}
                  />

                  <TextInput
                    name="currentPosition"
                    placeholder="Current Position"
                    type="text"
                    className="mt-2 w-100"
                    {...register("current_position", {
                      required: {
                        value: true,
                        message: "You need to input your current position",
                      },
                      pattern: {
                        value: VALIDATE.TEXTONLY,
                        message:
                          "Current position cannot contain non-alphabetical characters",
                      },
                    })}
                  />

                  <ErrorMessage
                    errors={errors}
                    name="current_position"
                    render={({ message }) => (
                      <p style={{ color: "red" }}>{message}</p>
                    )}
                  />

                  <Select
                    name="affiliation"
                    className="mt-2 w-100"
                    {...register("affiliation", {
                      required: {
                        value: true,
                        message: "Affiliation is required.",
                      },
                    })}
                  >
                    <option key="" value="">
                      Select Affiliation...
                    </option>
                    {universities.map((university) => (
                      <option value={university} key={university}>
                        {university}
                      </option>
                    ))}
                  </Select>
                  <div style={{ fontSize: "8px", color: "#070d59" }}>
                    If you can't find your affiliation, please try again later.
                    We are continously working on adding more institutions.
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="affiliation"
                    render={({ message }) => <p>{message}</p>}
                  />

                  <TextInput
                    name="email"
                    type="email"
                    placeholder="Email"
                    className="mt-2 w-100"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "You need to input an email address.",
                      },
                      pattern: VALIDATE.EMAIL,
                    })}
                  />

                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => (
                      <p style={{ color: "red" }}>{message}</p>
                    )}
                  />

                  <TextInput
                    name="password"
                    placeholder="New Password"
                    type="password"
                    className="mt-2 w-100"
                    {...register("password", {
                      required: {
                        value: true,
                        message: "Password cannot be empty",
                      },
                    })}
                  />

                  <ErrorMessage
                    errors={errors}
                    name="password"
                    render={({ message }) => (
                      <p style={{ color: "red" }}>{message}</p>
                    )}
                  />

                  <TagInput
                    inputProps={{
                      placeholder:
                        interestsArray.length > 0
                          ? "Add Interests..."
                          : "Enter up to " + MAX_ATTRIBUTES + " interests",
                    }}
                    className="mt-2 w-100"
                    values={interestsArray}
                    onChange={(vals) =>
                      handleTagSubmit(vals, SetInterestsArray)
                    }
                  />
                  {formData.accountType === "student" && (
                    <TagInput
                      inputProps={{
                        placeholder:
                          skillsArray.length > 0
                            ? "Add Skills..."
                            : "Enter up to " + MAX_ATTRIBUTES + " skills",
                      }}
                      className="mt-2 w-100"
                      values={skillsArray}
                      onChange={(vals) => handleTagSubmit(vals, SetSkillsArray)}
                    />
                  )}
                  <Button
                    appearance="primary"
                    type="submit"
                    className="w-100 mt-2"
                    isLoading={loading}
                    backgroundColor={color.primary}
                    disabled={true}
                  >
                    Coming soon
                  </Button>
                  <Button
                    appearance="minimal"
                    className="mt-2"
                    onClick={() => setUserTypeSelected(false)}
                    iconBefore={ArrowLeftIcon}
                    color={color.primary}
                  >
                    Back
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
        <div className="col-md-6">
          <img src={hero} className="col" />
        </div>
      </div>
    </div>
  );
};
export default Register;
