import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  Button,
  Text,
  SideSheet,
  TickIcon,
  DisableIcon,
  Heading,
  Pane,
  Dialog,
  TickCircleIcon,
  toaster,
  ArrowTopRightIcon,
  Badge,
  Icon,
  PanelTableIcon,
  TrashIcon,
  CrossIcon,
  SmallCrossIcon,
  IconButton,
} from "evergreen-ui";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelIcon from "@material-ui/icons/Cancel";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import styles from "./ListItem.scss";
import Draggable from "react-draggable";
import PopUp from "./PopUp";
import {
  AcceptApplication,
  RejectApplication,
} from "../../requests/application";
import linkedin from "../../assets/icons/linkedin.png";
import { Confirm } from "../Common/Confirm";
import { IconText } from "../Common/IconText";
import {
  GetProposedTimes,
  GetAcceptedTimes,
  DeleteProposedTime,
} from "../../requests/interview";
import { convertToSQLDatetime } from "../../services/time";

function ListItem(props) {
  const [isShown, setIsShown] = useState();
  const [offered, setIsOffered] = useState();
  const { application } = props;
  const [confirmAccept, setConfirmAccept] = useState(false);
  const [confirmReject, setConfirmReject] = useState(false);
  const [proposedInterviewTimes, setProposedInterviewTimes] = useState([]);
  const [acceptedInterviewTime, setAcceptedInterviewTime] = useState();
  const [openInterviewPicker, setOpenInterviewPicker] = useState(false);

  const ACCEPTED = application.status == "OFFER" || offered;
  const STATUS_OFFERED = "Accepted";
  const STATUS_INT_ACCEPTED = "Interview Accpeted";
  const STATUS_INT_PROPOSED = "Interview Proposed";
  const STATUS_IN_REVIEW = "In Review";

  const statusColors = {
    [ACCEPTED]: "green",
    [STATUS_INT_ACCEPTED]: "teal",
    [STATUS_INT_PROPOSED]: "blue",
    [STATUS_IN_REVIEW]: "blue",
  };
  const STATUS = ACCEPTED
    ? STATUS_OFFERED
    : acceptedInterviewTime
    ? STATUS_INT_ACCEPTED
    : proposedInterviewTimes?.length
    ? STATUS_INT_PROPOSED
    : STATUS_IN_REVIEW;

  function UpdateAcceptance(status) {
    if (status && !ACCEPTED) {
      AcceptApplication(props.app_id, props.application.opportunity_id);
      setIsOffered(true);
    }
  }

  useEffect(() => {
    GetProposedTimes(application.id).then((res) => {
      const interviewTimes = res.map((interview) => interview.time);
      setProposedInterviewTimes(interviewTimes);
    });
  }, [openInterviewPicker, isShown]);

  useEffect(() => {
    GetAcceptedTimes(application.id).then((res) => {
      const interviewTime = res?.length && res[0]?.time;
      setAcceptedInterviewTime(interviewTime);
    });
  }, []);

  return (
    <>
      <SideSheet
        isShown={isShown}
        title={application.name}
        onCloseComplete={() => setIsShown(false)}
        hasFooter={false}
      >
        <Pane borderBottom={true} marginTop={8} padding={16}>
          <h3>About {application.name}</h3>
        </Pane>
        <Pane flex="1" overflowY="scroll" padding={16}>
          {proposedInterviewTimes.length > 0 && (
            <Pane marginBottom={16}>
              <h5 className="font-weight-bold mr-2">
                Proposed Interview Times:
              </h5>
              {proposedInterviewTimes.map((interviewTime) => {
                return (
                  <Pane display="flex" justifyContent="space-between">
                    <Badge color="blue">{interviewTime}</Badge>
                    <Button
                      onClick={() => {
                        const sqlTime = convertToSQLDatetime(
                          new Date(interviewTime)
                        );
                        DeleteProposedTime(application.id, sqlTime).then(
                          toaster.notify("Interview Time Deleted")
                        );
                        setIsShown(false);
                      }}
                      appearance="minimal"
                      intent="danger"
                    >
                      <Icon icon={SmallCrossIcon} />
                      Delete
                    </Button>
                  </Pane>
                );
              })}
            </Pane>
          )}
          {acceptedInterviewTime?.length > 0 && (
            <Pane marginBottom={16}>
              <h5 className="font-weight-bold mr-2">
                Accepted Interview Time:
              </h5>
              <Badge color="blue">{acceptedInterviewTime}</Badge>
            </Pane>
          )}
          {application.about_me && (
            <Pane display="flex" marginBottom={16}>
              <h5 className="font-weight-bold mr-2">About them:</h5>
              <h5>{application.about_me}</h5>
            </Pane>
          )}
          {application.interests && (
            <Pane display="flex" marginBottom={16}>
              <h5 className="font-weight-bold mr-2">Interests:</h5>
              <h5>{application.interests}</h5>
            </Pane>
          )}
          {application.email_username && (
            <Pane display="flex" marginBottom={16}>
              <h5 className="font-weight-bold mr-2">Email:</h5>
              <h5>{application.email_username}</h5>
            </Pane>
          )}
          {application.linkedin_url && (
            <a href={"http://www." + application.linkedin_url} target="_blank">
              <Button marginRight={16}>See Linkedin Profile Here</Button>
            </a>
          )}
          {application.research_gate_url && (
            <a href={application.research_gate_url} target="_blank">
              <Button>See Reseach Gate Profile Here</Button>
            </a>
          )}
        </Pane>
      </SideSheet>
      <Table.Row>
        <Table.TextCell cursor="pointer" onClick={() => setIsShown(true)}>
          <Text color="#3366FF">{application.name}</Text>
        </Table.TextCell>
        <Table.TextCell>
          <Pane cursor="pointer" onClick={() => toaster.notify("Coming soon!")}>
            <IconText iconBefore={ArrowTopRightIcon} color="#3366FF">
              View Resume
            </IconText>
          </Pane>
        </Table.TextCell>
        <Table.TextCell>
          <Pane cursor="pointer" onClick={() => toaster.notify("Coming soon!")}>
            <IconText iconBefore={ArrowTopRightIcon} color="#3366FF">
              View Cover Letter
            </IconText>
          </Pane>
        </Table.TextCell>
        <Table.TextCell textAlign="center">
          <Badge
            cursor="pointer"
            onClick={() => setIsShown(true)}
            color={statusColors[STATUS]}
          >
            {STATUS}
          </Badge>
        </Table.TextCell>
        <Table.TextCell flexBasis={320} flexShrink={0} flexGrow={0}>
          <Pane display="flex" justifyContent="center">
            {ACCEPTED ? (
              <IconText color="success" iconBefore={TickCircleIcon}>
                Offer Sent
              </IconText>
            ) : (
              <>
                {STATUS == STATUS_IN_REVIEW && (
                  <PopUp
                    application={props.app_id}
                    open={openInterviewPicker}
                    setOpen={setOpenInterviewPicker}
                  />
                )}
                <Button
                  appearance="primary"
                  intent="success"
                  iconBefore={TickIcon}
                  onClick={() => setConfirmAccept(true)}
                  marginLeft={8}
                >
                  Accept
                </Button>
                <Button
                  iconBefore={DisableIcon}
                  appearance="primary"
                  intent="danger"
                  marginLeft={8}
                  onClick={() => setConfirmReject(true)}
                >
                  Reject
                </Button>
              </>
            )}
          </Pane>
        </Table.TextCell>
        <Confirm
          isShown={confirmAccept}
          setIsShown={setConfirmAccept}
          title={"Accept Candidate"}
          message={`Are you sure you'd like to accept ${application.name}?`}
          onConfirm={() => {
            UpdateAcceptance(true);
            setConfirmAccept(false);
          }}
        />
        <Confirm
          isShown={confirmReject}
          setIsShown={setConfirmReject}
          intent="danger"
          title={"Reject Candidate"}
          message={`Are you sure you'd like to reject ${application.name}?`}
          onConfirm={() => {
            RejectApplication(props.app_id, props.application.email);
            props.deleteApplication(props.app_id);
            setConfirmReject(false);
          }}
        />
      </Table.Row>
    </>
  );
}

ListItem.propTypes = {
  application: PropTypes.any,
  deleteItem: PropTypes.func,
};

export default ListItem;
