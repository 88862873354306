/*
Defines methods for fetching opportunities. All fetches inserting/updating
/reading opportunities should be defined here.
*/
const ROUTE = "/api/user";

/* 
User = {
        email: string,
        id: uuid,
        name: string,
        affiliation: string,
        position: string,
}
*/

export function CreateUser(user) {
  return fetch(ROUTE, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      content: user,
    }),
  });
}

export function GetUser(id) {
  //return fetch(ROUTE + "/" + id);
  let user = fetch(`${ROUTE}/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      return response.json();
    }
  });
  return user;
}
