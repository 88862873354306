import React, { useEffect } from "react";
import styles from "./Postings.module.css";
import { Badge } from "evergreen-ui";
import { Link, useLocation, useRouteMatch, useHistory } from "react-router-dom";

export default function PostingThumbnail(props) {
  let match = useRouteMatch();

  const currentTime = new Date();
  const postedTime = new Date(props.data.start_date);
  const daysAgo = Math.ceil(
    (currentTime.getTime() - postedTime.getTime()) / (1000 * 3600 * 24)
  );

  return (
    <Link
      to={`${match.path}/${props.data.post_id}`}
      className="text-decoration-none"
    >
      <div className={styles.thumbnailCard + " mb-4"} {...props}>
        <div className="py-4 px-4">
          {props.data.is_fulltime === 1 ? (
            <Badge color="blue" className="mb-3">
              Full Time
            </Badge>
          ) : (
            <Badge color="orange" className="mb-3">
              Part Time
            </Badge>
          )}
          <h3>{props.data.position_name}</h3>
          <p className="color-light">
            {props.data.affiliation} | {props.data.city}
          </p>
          {/* Caps the thumbnail desc to 50 chars if the length is more than 50 */}
          <p>
            {props.data.position_description?.length > 50
              ? props.data.position_description.Substring(0, 50) + "..."
              : props.data.position_description}
          </p>
          <p className="color-light">{daysAgo} Days Ago</p>
        </div>
      </div>
    </Link>
  );
}

export function OpportunityThumbnail(props) {
  let match = useRouteMatch();

  const currentTime = new Date();
  const postedTime = new Date(props.data.start_date);
  const daysAgo = Math.ceil(
    (currentTime.getTime() - postedTime.getTime()) / (1000 * 3600 * 24)
  );

  return (
    <div className={styles.thumbnailCard + " mb-4"} {...props}>
      <div className="py-4 px-4">
        {props.data.is_fulltime === 1 ? (
          <Badge color="blue" className="mb-3">
            Full Time
          </Badge>
        ) : (
          <Badge color="orange" className="mb-3">
            Part Time
          </Badge>
        )}
        <h3>{props.data.position_name}</h3>
        <p className="color-light">
          {props.data.institution} | {props.data.city}
        </p>
        {/* Caps the thumbnail desc to 50 chars if the length is more than 50 */}
        <p>
          {props.data.position_description?.length > 50
            ? props.data.position_description.substring(0, 50) + "..."
            : props.data.position_description}
        </p>
        <p className="color-light">
          Posted {daysAgo} Day{daysAgo != 1 ? "s" : ""} Ago
        </p>
      </div>
    </div>
  );
}
