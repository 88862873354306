/*
Defines methods for fetching opportunities. All fetches inserting/updating
/reading opportunities should be defined here.
*/

export const ROUTE = "http://localhost:3000/api/opportunity";

/* 
Opportunity = {
  host_id: uid,
  position_name: string,
  position_description: string,
  position_qualification: string,
  affiliation: string,
  start_date: new Date(),
  is_extendible: bool,
  is_remote: bool,
  is_fulltime: false,
  cv_required: bool,
  cl_required: bool,
  is_email_visible: bool,
  is_funded: bool,
  funding_details varchar(255),
  is_acknowledgment: bool,
  is_authorship: bool,
  is_reference: bool,
  num_references: int, 0 <= num_references <= 3
  address: string,
}
*/

export async function getOpportunity(id) {
  //Fetch an opportunity with the given /:id
  let result = await fetch(`${ROUTE}/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      return response.json();
    }
  });
  return result;
}

// Encodes URL query params for extra security.
function objToQueryString(obj) {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(
      encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
    );
  }
  return keyValuePairs.join("&");
}
/*
Returns all opportunities satisfying the input filters.
If no filters are given, returns all opportunities.
filters:
  position_name: string,
  affiliation: int,
  is_extendible: bool,
  is_remote: bool,
  is_fulltime: false,
  cv_required: bool,
  cl_required: bool,
  is_funded: bool,
  is_acknowledgment: bool,
  is_authorship: bool,
  num_references: int, 0 <= num_references <= 3
Precondition: filters are correctly populated.
*/
export function GetOpportunitiesList(filters = null) {
  let url = ROUTE;
  if (filters !== null) {
    console.log("Here are the filters:");
    console.log(filters);
    const queryString = objToQueryString(filters);
    url += `?${queryString}`;
  }
  console.log("End result url");
  let opportunities = fetch(url)
    .then((response) => {
      if (!response.ok) {
        console.log(response.statusText);
      } else {
        console.log("Response ok");
        return response.json();
      }
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
  return opportunities;
}

export function GetPostingCount(id) {
  //Fetch the number of postings by user with id = /:id
  return fetch(`${ROUTE}/count/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      return response.json();
    }
  });
}

export async function GetPostedOpportunties(id) {
  //Fetch an opportunity with the given /:id
  console.log(`Id passed to routes ${id}`);
  return await fetch(`${ROUTE}/posted/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      return response.json();
    }
  });
}

export function sendOpportunity(opportunity) {
  return fetch(ROUTE, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      content: opportunity,
    }),
  });
}
