import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useGlobalState } from "../../App";
import { SignOut as SignOutUser } from "./util";
import { toaster } from "evergreen-ui";
import { useHistory } from "react-router-dom";

const SignOut = () => {
  const [isLoggedOut, SetIsLoggedOut] = useState(false);
  const [_, dispatch] = useGlobalState();
  let history = useHistory();

  useEffect(() => {
    try {
      SignOutUser().then((res) => {
        dispatch({ is_logged_in: false, user: null });
        SetIsLoggedOut(true);
        history.push("/login");
      });
    } catch (error) {
      toaster.danger("Error signing out! Please try again.");
    }
  }, []);

  return (
    <div>
      {isLoggedOut && <Redirect to="/" />}
      {!isLoggedOut && <h4>logOutError</h4>}
    </div>
  );
};

export default SignOut;
