import React, { useState, useEffect } from "react";

import {
  Avatar,
  Pane,
  Table,
  Badge,
  Button,
  TickIcon,
  DisableIcon,
  toaster,
} from "evergreen-ui";

import {
  AcceptInterviewTime,
  RejectInterviewTime,
} from "../../requests/interview";
import { friendlyTimeString, convertToSQLDatetime } from "../../services/time";
import { useGlobalState } from "../../App";

export default function ProposedTimes(props) {
  const [state, dispatch] = useGlobalState();
  const [Interviews, SetInterviews] = useState([]);

  function Accept(interview) {
    const FormattedDatetime = convertToSQLDatetime(new Date(interview.time));
    AcceptInterviewTime(interview.application, FormattedDatetime).then(
      (res) => {
        if (res.ok) {
          toaster.success("Time Accepted. We'll let your interviewer know!");
          SetInterviews(
            Interviews.filter((i) => interview.application != i.application)
          );
        } else {
          toaster.danger("Failed to accept this proposed time");
        }
      }
    );
  }

  function Reject(interview) {
    const FormattedDatetime = convertToSQLDatetime(new Date(interview.time));
    RejectInterviewTime(interview.application, FormattedDatetime).then(
      (res) => {
        if (res.ok) {
          toaster.success("Time Rejected. We'll let your interviewer know!");
          SetInterviews(
            Interviews.filter(
              (i) =>
                interview.researcher_name != i.researcher_name ||
                i.time != interview.time
            )
          );
        } else {
          toaster.danger("Failed to reject this proposed time");
        }
      }
    );
  }

  useEffect(() => {
    SetInterviews(props.Interviews);
  }, [props.Interviews]);

  return (
    <Pane marginTop={32}>
      <Table>
        <Table.Head>
          <Table.TextCell>Researcher Details</Table.TextCell>
          <Table.TextCell>Time Offered</Table.TextCell>
          <Table.TextCell flexBasis={380} flexShrink={0} flexGrow={0}>
            Actions
          </Table.TextCell>
        </Table.Head>
        <Table.Body>
          {Interviews &&
            Interviews.map((interview) => {
              return (
                <Table.Row key={interview.researcher_name}>
                  <Table.TextCell>
                    <div className="m-0 row align-items-center">
                      <Avatar
                        name="researcher_name"
                        size={30}
                        marginRight={12}
                      />
                      {interview.researcher_name}
                    </div>
                  </Table.TextCell>
                  <Table.TextCell>
                    <Badge color="neutral">
                      {friendlyTimeString(new Date(interview.time))}
                    </Badge>
                  </Table.TextCell>
                  <Table.TextCell flexBasis={380} flexShrink={0} flexGrow={0}>
                    <Button
                      appearance="primary"
                      intent="success"
                      iconBefore={TickIcon}
                      marginRight={16}
                      onClick={() => Accept(interview)}
                    >
                      Accept
                    </Button>
                    <Button marginRight={16}>Reschedule</Button>
                    <Button
                      appearance="primary"
                      intent="danger"
                      iconBefore={DisableIcon}
                      onClick={() => Reject(interview)}
                    >
                      Decline
                    </Button>
                  </Table.TextCell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </Pane>
  );
}
