import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { AcceptInterviewTime } from "../../requests/interview";
import styles from "./interviewCard.module.css";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

//Material UI Custom class
export default function InterviewCard(props) {
  const classes = useStyles();
  const bull = <span className={styles.bullet}>•</span>;
  const interview_datetime = new Date(props.invitation.time);
  const interview_date = `${interview_datetime.getFullYear()}-${interview_datetime.getMonth()}-${interview_datetime.getDate()}`;
  const interview_time = `${interview_datetime.getUTCHours()}:${interview_datetime.getUTCMinutes()}`;

  function AcceptTime() {
    console.log("Accepting Interview Time");
    const formatted_datetime = `${interview_date} ${interview_time}`;
    console.log("Invitation : ", props.invitation);
    AcceptInterviewTime(props.invitation.application_id, formatted_datetime);
  }

  useEffect(() => {
    console.log(props);
    console.log(interview_date);
  }, []);

  return (
    <Card className={styles.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {interview_date}
        </Typography>

        <Typography variant="body2" component="p">
          Start : {interview_time}
          <br />
          ------
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={AcceptTime} size="small">
          Accept
        </Button>
      </CardActions>
    </Card>
  );
}
