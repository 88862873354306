import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./routing/private_route";
import { CONDITIONS } from "./routing/private_route_conditions";

import "./css/index.css";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Landing from "./components/Landing";

import Account from "./components/Login";
import Register from "./components/Login/Register";
import SignOut from "./components/Login/SignOut";
import Apply from "./components/Apply";

import Postings from "./components/Postings";
import Opportunities from "./components/OpportunitiesOld";
import CreateOpportunities from "./components/CreateOpportunities";
import User from "./components/User/index";
import Profile from "./components/Profile";
import MyOpportunties from "./components/MyOpportunties/index";
import { GetAllInstitutions } from "./requests/institution";

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";
import { ControlPointSharp } from "@material-ui/icons";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBtChUrsKQCWECl-dT_VNKjfunZYSomOwU",
  authDomain: "academiamatch-1bae1.firebaseapp.com",
  databaseURL: "https://academiamatch-1bae1.firebaseio.com",
  projectId: "academiamatch-1bae1",
  storageBucket: "academiamatch-1bae1.appspot.com",
  messagingSenderId: "829999361197",
  appId: "1:829999361197:web:1d6dbadaf911ad0883ea24",
  measurementId: "G-TDKHVQDB4E",
};

const defaultGlobalState = {
  is_logged_in: false,
  user: null,
  is_initialized: false,
};
const GlobalStateContext = React.createContext(defaultGlobalState);
// const GlobalStateContext = React.createContext(undefined);
const DispatchStateContext = React.createContext(undefined);

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    (state, newValue) => ({ ...state, ...newValue }),
    defaultGlobalState
  );
  return (
    <GlobalStateContext.Provider value={state}>
      <DispatchStateContext.Provider value={dispatch}>
        {children}
      </DispatchStateContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => [
  React.useContext(GlobalStateContext),
  React.useContext(DispatchStateContext),
];

// Initialize Firebase
if (firebase.apps.length == 0) {
  firebase.initializeApp(firebaseConfig);
}

const ConstProviders = (props) => {
  // TODO: Either delete this call or move services/affiliations.js
  // to backend.
  useEffect(() => {
    console.log("Fill Institutions Called");
    async function fillInstitutions() {
      const AllInstitutions = await GetAllInstitutions();
    }
    fillInstitutions();
  }, [props.institutions]);
  return null;
};

const App = () => {
  return (
    <GlobalStateProvider>
      <ConstProviders />
      <div className="d-flex flex-column min-vh-100">
        <NavBar />
        <main className="flex-fill">
          <Switch>
            <Route path="/signout" component={SignOut} />
            <Route path="/apply" component={Apply} />
            <PrivateRoute
              path="/Opportunities/:id"
              role={CONDITIONS.IS_SIGNED_IN}
              component={Opportunities}
            />
            <PrivateRoute
              path="/Postings"
              role={CONDITIONS.IS_SIGNED_IN}
              component={Postings}
            />
            <PrivateRoute
              path="/login"
              role={CONDITIONS.IS_NOT_SIGNED_IN}
              component={Account}
            />
            <PrivateRoute
              path="/register"
              role={CONDITIONS.IS_NOT_SIGNED_IN}
              component={Register}
            />
            <PrivateRoute
              path="/CreateOpportunities"
              role={CONDITIONS.IS_RESEARCHER}
              component={CreateOpportunities}
            />
            <Route exact path="/User/:id" component={User} />
            <PrivateRoute
              exact
              path="/MyOpportunties"
              role={CONDITIONS.IS_RESEARCHER}
              component={MyOpportunties}
            />
            <PrivateRoute
              path="/profile"
              role={CONDITIONS.IS_STUDENT}
              component={Profile}
            />
            <Route exact path="/" component={Landing} />
          </Switch>
        </main>
        <Footer />
      </div>
    </GlobalStateProvider>
  );
};

export default App;
