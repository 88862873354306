const CONSTANTS = {};

CONSTANTS.ERROR_MESSAGE = {};

CONSTANTS.ERROR_MESSAGE.LIST_DELETE = "Request to delete list item failed:";
CONSTANTS.ERROR_MESSAGE.LIST_ADD = "Request to add list item failed:";
CONSTANTS.ERROR_MESSAGE.LIST_GET = "Request to get list items failed:";
CONSTANTS.ERROR_MESSAGE.EMPTY_FIELD = "One field is missing.";

CONSTANTS.ENDPOINT = {};

CONSTANTS.ENDPOINT.LIST = "/api/list";
CONSTANTS.ENDPOINT.OPPORTUNTIES = "/api/opportunties";

export default CONSTANTS;
