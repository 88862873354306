import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import DateFnsUtils from "@date-io/date-fns";
import { Button, Dialog, Pane, PlusIcon, toaster } from "evergreen-ui";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import SendIcon from "@material-ui/icons/Send";
import { SetProposedTimes } from "../../requests/interview";
import { convertToUTC, convertToSQLDatetime } from "../../services/time";
import { columnsTotalWidthSelector } from "@material-ui/data-grid";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    borderRadius: "20px",
    boxShadow: theme.shadows[8],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function PopUp(props) {
  const classes = useStyles();
  const [InterviewDates, handleDatesChange] = useState([
    { date: new Date(), startTime: new Date(), endTime: new Date() },
  ]);
  const timezone = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "long" })
    .split(" ")
    .slice(2)
    .join(" ");
  const DATE = "date";
  const START_TIME = "startTime";
  const END_TIME = "endTime";

  const IsValidInterviewDates = () => {
    for (let i = 0; i < InterviewDates.length; i++) {
      if (InterviewDates[i].endTime <= InterviewDates[i].startTime) {
        toaster.warning("Interview end time must be after the start time");
        return false;
      }
    }

    for (let i = 0; i < InterviewDates.length; i++) {
      for (let j = i + 1; j < InterviewDates.length; j++) {
        const firstDate = InterviewDates[i];
        const secondDate = InterviewDates[j];
        if (
          firstDate.date.getFullYear() == secondDate.date.getFullYear() &&
          firstDate.date.getMonth() == secondDate.date.getMonth() &&
          firstDate.date.getDate() == secondDate.date.getDate()
        ) {
          // Same date
          if (
            !(
              firstDate.startTime >= secondDate.endTime ||
              secondDate.startTime >= firstDate.endTime
            )
          ) {
            // Overlapping time
            toaster.warning("Interview times cannot overlap");
            return false;
          }
        }
      }
    }
    return true;
  };

  function SendTimeProposals() {
    if (!IsValidInterviewDates()) return;

    for (const interviewDate of InterviewDates) {
      const tempDate = new Date(
        interviewDate.date.getFullYear(),
        interviewDate.date.getMonth(),
        interviewDate.date.getDate(),
        interviewDate.startTime.getHours(),
        interviewDate.startTime.getMinutes()
      );
      SetProposedTimes(props.application, convertToSQLDatetime(tempDate)).then(
        (res) => {
          if (res.ok) {
            toaster.success("Interview time sent");
            props.setOpen(false);
          } else {
            toaster.danger("Failed to propose interview time");
          }
        }
      );
    }
  }

  const AddTime = () => {
    return (
      <Button
        marginTop={32}
        onClick={() => {
          handleDatesChange((prev) => [
            ...prev,
            { date: new Date(), startTime: new Date(), endTime: new Date() },
          ]);
        }}
        iconBefore={PlusIcon}
      >
        Add another time
      </Button>
    );
  };

  const MeetingInformation = () => {
    return (
      <FormControl className="ml-4 mt-3">
        <InputLabel htmlFor="input-with-icon-adornment">
          Meeting Link
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          }
        />
      </FormControl>
    );
  };

  const handleInterviewDateChange = (idx, property, val) => {
    let newInterviewDates = InterviewDates;
    newInterviewDates[idx][property] = val;
    handleDatesChange([...newInterviewDates]);
  };

  const ProposedInterview = ({ idx }) => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          className="mr-3"
          id="date-picker-dialog"
          label="Date"
          defaultValue="2021-07-05"
          format="MM-dd-yyyy"
          value={InterviewDates[idx].date}
          onChange={(val) => {
            handleInterviewDateChange(idx, DATE, val);
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <KeyboardTimePicker
          className="mr-3"
          margin="normal"
          id="time-picker"
          label="Start"
          value={InterviewDates[idx].startTime}
          onChange={(val) => {
            handleInterviewDateChange(idx, START_TIME, val);
          }}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          label="End"
          value={InterviewDates[idx].endTime}
          onChange={(val) => {
            handleInterviewDateChange(idx, END_TIME, val);
          }}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />
        <MeetingInformation />
      </MuiPickersUtilsProvider>
    );
  };

  return (
    <div>
      <Button onClick={() => props.setOpen(true)}>Interview</Button>
      <Dialog
        isShown={props.open}
        onConfirm={SendTimeProposals}
        onCloseComplete={() => props.setOpen(false)}
        title="Interview Times"
      >
        {InterviewDates.map((interviewDate, idx) => (
          <>
            <ProposedInterview idx={idx} />
            <br />
          </>
        ))}
        <p>
          Use your local time zone.
          <br />
          Current timezone: {timezone}
        </p>
        <AddTime />
      </Dialog>
    </div>
  );
}
