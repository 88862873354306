import React, { useState, useEffect } from "react";
import {
  TextInput,
  Pane,
  Switch,
  SelectMenu,
  Button,
  Select,
  Textarea,
} from "evergreen-ui";
import DatePicker from "react-datepicker";
import { sendOpportunity } from "../../requests/opportunity";
import "./createOpportunities.module.css";
import "react-dropdown/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from "react-router-dom";
import { useGlobalState } from "../../App";
import { GetUser } from "../../requests/user";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { GetAllowedAffiliations } from "../../services/affiliations";

const NumReferencesOptions = [0, 1, 2, 3];
const VALIDATE = {};
VALIDATE.TEXTONLY = /^([^0-9]*)$/;

const CreateOpportunities = () => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    //Values that are boolean reversed in code can't start as undefined
    defaultValues: {
      is_funded: false,
      cv_required: false,
      cl_required: false,
      is_reference: false,
      is_acknowledgment: false,
      is_authorship: false,
      is_extendible: false,
    },
  });
  const [uid, SetUserID] = useState("NO_USER_ID");
  const [positionName, SetPositionName] = useState("");
  const [positionDescription, SetPositionDescription] = useState("");
  const [positionQualification, SetPositionQualification] = useState("");
  const [positionResponsibilities, SetPositionResponsibilities] = useState("");
  const [affiliation, SetAffiliation] = useState("");
  const [startDate, SetStartDate] = useState(new Date());
  const [isFulltime, SetIsFulltime] = useState(false);
  const [universities, SetUniversities] = useState([]);
  const [numReferences, SetNumReferences] = useState(0);
  // Is application submitted successfully.
  const [success, SetSuccess] = useState(false);
  const [state, _] = useGlobalState();
  const [name, setName] = useState();
  const [hostId, setHostId] = useState();

  useEffect(() => {
    SetResearcher(state?.user?.uid);
    // SetResearcher("PfesfrrdkZwaik");
  }, [state]);

  const SetResearcher = (id) => {
    GetUser(id).then((res) => {
      if (res?.length) {
        setHostId(res[0].id);
        setName(res[0].name);
      }
    });
  };

  useEffect(() => {
    if (state.user) {
      SetUserID(state.user.uid);
    } else {
      SetUserID("");
    }
    SetUniversities(GetAllowedAffiliations());
  }, []);

  function SubmitForm() {
    //getValues() returns values of all form fields
    const opportunity = getValues();

    opportunity["host_id"] = uid;
    opportunity["is_email_visible"] = false;
    opportunity["start_date"] = startDate;
    opportunity["is_fulltime"] = getValues().is_fulltime === "true";
    opportunity["num_references"] = numReferences;

    sendOpportunity(opportunity).then((response) => {
      if (!response.ok) {
        alert("Couldn't submit, try again later! (or check the console)");
        console.log(response.statusText);
      } else {
        SetSuccess(true);
      }
    });
  }

  const PostingInput = ({
    placeholder,
    title,
    validation,
    errorName,
    showError = true,
  }) => {
    return (
      <Pane display="flex" height={56} justifyContent="space-between">
        <h5 className="mr-3">{title}</h5>
        <Pane>
          <TextInput {...validation} width={512} placeholder={placeholder} />
          {showError && (
            <Pane display="flex" justifyContent="flex-end">
              <ErrorMessage
                errors={errors}
                name={errorName}
                render={({ message }) => <p>{message}</p>}
              />
            </Pane>
          )}
        </Pane>
      </Pane>
    );
  };

  const PostingSwitch = ({ name, value, title, marginTop = 0 }) => {
    return (
      <Pane
        display="flex"
        marginTop={marginTop}
        marginBottom={16}
        alignItems="center"
        justifyContent="space-between"
      >
        <h5 className="mr-3">{title}</h5>
        <input type="checkbox" name={name} {...value} />
      </Pane>
    );
  };

  return (
    <form id="mainContent" onSubmit={handleSubmit(SubmitForm)}>
      {success && <Redirect to={{ pathname: "/MyOpportunties" }} />}
      <div className="container margin-t-large">
        <h2 className="my-4">New Posting</h2>
        {PostingInput({
          title: "Position Name:",
          placeholder: "Machine Learning RA",
          validation: register("position_name", {
            required: {
              value: true,
              message: "You need to input a position name",
            },
            pattern: {
              value: VALIDATE.TEXTONLY,
              message:
                "You cannot have non-alphabetical input in position name",
            },
          }),
          errorName: "position_name",
        })}
        {PostingInput({
          title: "Description:",
          placeholder: "Description",
          validation: register("position_description", {
            required: {
              value: true,
              message: "You need to input a position description",
            },
            pattern: {
              value: VALIDATE.TEXTONLY,
              message:
                "Position description cannot contain non-alphabetical input",
            },
          }),
          errorName: "position_description",
        })}
        <Pane
          display="flex"
          marginBottom={16}
          alignItems="center"
          justifyContent="space-between"
        >
          <h5 className="mr-3">Duties and Responsibilities</h5>
          <Pane>
            <Textarea
              width={512}
              name="responsibilities"
              {...register("position_responsibilities", {
                required: {
                  value: true,
                  message:
                    "You need to input position roles and responsibilities",
                },
              })}
              placeholder={"On a regular-basis, you will be..."}
            />
            <Pane display="flex" justifyContent="flex-end">
              <ErrorMessage
                errors={errors}
                name="position_responsibilities"
                render={({ message }) => <p>{message}</p>}
              />
            </Pane>
          </Pane>
        </Pane>
        {PostingInput({
          title: "Qualification:",
          placeholder: "BSc",
          validation: register("position_qualification", {
            required: {
              value: true,
              message: "You need to input a position qualification",
            },
            pattern: {
              value: VALIDATE.TEXTONLY,
              message:
                "You cannot have non-alphabetical input in position qualification",
            },
          }),
          errorName: "position_qualification",
        })}
        <Pane display="flex" height={36} justifyContent="space-between">
          <h5 className="mr-3">Affiliation:</h5>
          <Pane>
            <Select
              name="affiliation"
              className="w-100"
              {...register("affiliation", {
                required: {
                  value: true,
                  message: "You must select your institution",
                },
              })}
            >
              <option value="">Select Affiliation...</option>
              {universities.map((university, idx) => (
                <option value={university} key={university}>
                  {university}
                </option>
              ))}
            </Select>
            <Pane display="flex" justifyContent="flex-end">
              <ErrorMessage
                errors={errors}
                name="affiliation"
                render={({ message }) => <p>{message}</p>}
              />
            </Pane>
          </Pane>
        </Pane>
        <Pane
          display="flex"
          marginTop={16}
          alignItems="center"
          justifyContent="space-between"
        >
          <h5 className="mr-3">Start Date:</h5>
          <Pane>
            <DatePicker
              selected={startDate}
              onChange={(date) => SetStartDate(date)}
              customInput={<TextInput />}
            ></DatePicker>
          </Pane>
        </Pane>
        {PostingSwitch({
          marginTop: 16,
          name: "isExtendible",
          value: register("is_extendible", {
            setValue: !getValues().is_extendible,
          }),
          title: "Position may be extended after project is over:",
        })}
        {PostingSwitch({
          name: "isRemote",
          value: register("is_remote", {
            setValue: !getValues().is_remote,
          }),
          title: "Position will be fully/partially remote:",
        })}
        <Pane
          display="flex"
          marginTop={16}
          alignItems="center"
          justifyContent="space-between"
        >
          <h5 className="mr-3">Number of required references:</h5>
          <SelectMenu
            options={NumReferencesOptions.map((label) => ({
              label,
              value: label,
            }))}
            selected={numReferences.toString()}
            width={42}
            height={132}
            hasFilter={false}
            hasTitle={false}
            onSelect={(item) => SetNumReferences(item.value)}
          >
            <Button>{numReferences.toString()}</Button>
          </SelectMenu>
        </Pane>
        {PostingSwitch({
          marginTop: 16,
          name: "isCVRequired",
          value: register("cv_required", {
            setValue: !getValues().cv_required,
          }),
          title: "CV required:",
        })}
        {PostingSwitch({
          name: "isCLRequired",
          value: register("cl_required", {
            setValue: !getValues().cl_required,
          }),
          title: "Cover Letter required:",
        })}
        {PostingInput({
          title: "Address of position (Leave empty if position is remote):",
          placeholder: "# StreetName St., Toronto ON XXXXXX",
          showError: false,
          validation: register("address"),
        })}
        {PostingSwitch({
          value: register("is_funded", {
            setValue: !getValues().is_funded,
          }),
          name: "isFunded",
          title: "There is available funding for this position:",
        })}
        {getValues().is_funded &&
          PostingInput({
            name: "fundingDetails",
            placeholder: "$10K for 3 months.",
            showError: false,
            validation: register("funding_details"),
            title: "Funding Details:",
          })}
        <Pane>
          <h5>Acknowledgment details (check whichever is applicable):</h5>
        </Pane>
        <Pane marginLeft={16}>
          {PostingSwitch({
            marginTop: 16,
            name: "referenceLetter",
            value: register("is_reference", {
              setValue: !getValues().is_reference,
            }),
            title: "Reference Letter:",
          })}
          {PostingSwitch({
            name: "acknowledgment",
            value: register("is_acknowledgment", {
              setValue: !getValues().is_acknowledgment,
            }),
            title: "Acknowledgment:",
          })}
          {PostingSwitch({
            name: "authorship",
            value: register("is_authorship", {
              setValue: !getValues().is_authorship,
            }),
            title: "Authorship:",
          })}
        </Pane>
        <Pane
          display="flex"
          marginTop={16}
          alignItems="center"
          justifyContent="space-between"
        >
          <h5 className="mr-3">Type of commitment:</h5>
          <Pane>
            <div className="radio">
              <input
                type="radio"
                value={false}
                {...register("is_fulltime", {
                  required: {
                    value: true,
                    message: "Select the position commitment",
                  },
                })}
                readOnly
              />
              <label className="ml-2">Part-time (0 - 20 hrs/week)</label>
            </div>
            <div className="radio">
              <input
                type="radio"
                value={true}
                {...register("is_fulltime", {
                  required: {
                    value: true,
                    message: "Select the position commitment",
                  },
                })}
                readOnly
              />
              <label className="ml-2">Full-time (20 - 40 hrs/week)</label>
            </div>
            <Pane display="flex" justifyContent="flex-end">
              <ErrorMessage
                errors={errors}
                name="is_fulltime"
                render={({ message }) => <p>{message}</p>}
              />
            </Pane>
          </Pane>
        </Pane>
        <Pane display="flex" width="100%">
          <Button
            marginTop={16}
            appearance="primary"
            width="100%"
            type="submit"
          >
            Create
          </Button>
        </Pane>
      </div>
    </form>
  );
};
export default CreateOpportunities;
