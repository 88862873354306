import React, { useState, useEffect } from "react";
import ListItem from "./ListItem";
import { Button, Pane, ArrowLeftIcon, Text, Table } from "evergreen-ui";
import { GetApplications } from "../../requests/application";
import { getOpportunity } from "../../requests/opportunity";

function Opportunities({ opportunityId, onGoBack }) {
  const [opportunity, setOpportunity] = useState();
  const [applications, setApplications] = useState([]);
  const remainingApplications = applications?.filter(
    (app) => app.status !== "REJECTED"
  );

  const deleteApplication = (app_id) => {
    setApplications(
      applications.filter((application) => application.id != app_id)
    );
  };

  function getApplicants(opportunityId) {
    //Retrieve Applicants for a given opporunity passed in as a prop from MyOpportunties
    console.log(opportunityId);
    GetApplications(opportunityId).then((res) => {
      console.log("Getting applications");
      console.log(res);
      setApplications(res);
    });
  }

  useEffect(() => {
    console.log(opportunityId);
    getOpportunity(opportunityId).then((res) => {
      console.log("Here");
      if (res?.length) {
        console.log(res[0]);
        setOpportunity(res[0]);
      }
      getApplicants(opportunityId);
    });
  }, []);

  return (
    <div className="container margin-t-large">
      <h2>Welcome back, Dr. Amin</h2>
      <Pane marginTop={32}>
        <Button marginBottom={32} iconBefore={ArrowLeftIcon} onClick={onGoBack}>
          Back
        </Button>
        <h3 className={"mb-2"}>Applicants for {opportunity?.position_name}</h3>
        <Pane marginBottom={32}>
          <Text>View applications for your posted opportunity</Text>
        </Pane>
        <Table>
          <Table.Head>
            <Table.TextCell>Name</Table.TextCell>
            <Table.TextCell>Resume</Table.TextCell>
            <Table.TextCell>Cover Letter</Table.TextCell>
            <Table.TextCell textAlign="center">Status</Table.TextCell>
            <Table.TextCell
              textAlign="center"
              flexBasis={306}
              flexShrink={0}
              flexGrow={0}
            >
              Actions
            </Table.TextCell>
          </Table.Head>
          <Table.Body>
            {remainingApplications &&
              remainingApplications.map((application) => (
                <ListItem
                  app_id={application.id}
                  key={application.applicant_id}
                  application={application}
                  deleteApplication={deleteApplication}
                />
              ))}
            {!remainingApplications?.length && (
              <Pane textAlign="center">
                <Text>No applicants yet.</Text>
              </Pane>
            )}
          </Table.Body>
        </Table>
      </Pane>
    </div>
  );
}

export default Opportunities;
