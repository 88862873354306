/*
Defines methods for dealing with the entire interview flow. All fetches inserting/updating
/reading opportunities should be defined here.
*/

const ROUTE = "/api/interview";

export function SetProposedTimes(application, times) {
  //Propose interview times for an application
  return fetch(`${ROUTE}/ProposeInterviewTimes`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      content: {
        app_id: application,
        proposed_times: times,
      },
    }),
  });
}

export function GetProposedTimes(application) {
  //Retrieve proposed interview times for an application
  return fetch(`${ROUTE}/GetProposedTimes`, {
    method: "GET",
    headers: { "Content-Type": "application/json", application: application },
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      return response.json();
    }
  });
}

export function GetAcceptedTimes(application) {
  //Retrieve accepted interview time for an application
  return fetch(`${ROUTE}/GetAcceptedTimes`, {
    method: "GET",
    headers: { "Content-Type": "application/json", application: application },
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      return response.json();
    }
  });
}

export function DeleteProposedTime(application, time) {
  //Delete a proposed interview time
  return fetch(`${ROUTE}/DeleteProposedTime`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json", application, time },
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      return response.json();
    }
  });
}

export function GetStudentProposedTimes(user) {
  // Retrieve all interview times proposed to this user
  return fetch(`${ROUTE}/GetStudentProposedTimes`, {
    method: "GET",
    headers: { "Content-Type": "application/json", user: user },
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      return response.json();
    }
  });
}

export function GetInvitations(opportunity, user) {
  // Retrieve all proposed interview times for a user's application to opportunity
  return fetch(`${ROUTE}/GetInvitations`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      opportunity: opportunity,
      user: user,
    },
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      return response.json();
    }
  });
}

export function AcceptInterviewTime(application, time) {
  return fetch(`${ROUTE}/AcceptInterviewTime`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      application: application,
      time: time,
    },
  });
}

export function RejectInterviewTime(application, time) {
  return fetch(`${ROUTE}/RejectInterviewTime`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      application: application,
      time: time,
    },
  });
}
