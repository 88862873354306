import firebase from "firebase/app";
import { GetUser } from "./requests/user";

export function ReloadUser(state, dispatch) {
  firebase.auth().onAuthStateChanged(function (user) {
    // This should only run if the user state has actually changed.
    // Conditions for this to run:
    // 1- User goes from not logged in -> logged in.
    // 2- User clicks on email verification link.
    if (user && (!state.user || user.emailVerified != state.is_verified)) {
      try {
        GetUser(user.uid).then((res) => {
          console.log(res);
          if (res[0]) {
            console.log(res[0]);
            let currentUser = {
              is_researcher: res[0].is_hiring ? true : false,
              uid: res[0].id,
              ...res[0],
            };
            dispatch({
              is_logged_in: true,
              user: currentUser,
              is_verified: user.emailVerified,
              is_initialized: true,
            });
          } else {
            console.log(
              "Something wrong happened. User is not registered in database."
            );
          }
        });
      } catch (error) {
        console.log(error.message);
      }
      // TODO: Add verify email here to update database.
    } else {
      // Only dispatch if this is the first time initializing state to avoid infinite loop.
      if (!state.is_initialized) {
        dispatch({
          is_logged_in: false,
          user: null,
          is_verified: false,
          is_initialized: true,
        });
      }
      console.log("No user found in local state");
    }
  });
}
