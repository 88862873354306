import { CreateUser, GetUser } from "../../requests/user";
import { CreateSkillsEntry, GetSkills } from "../../requests/skills";
// Add the Firebase services that you want to use
import firebase from "firebase/app";

import MockUserHandler from "./mockUserHandler";
import "firebase/auth";

export const dev = "dev";
export const prod = "prod";
// Note: Do not modify this variable unless got explicit approval.
export const env = prod;

export function EnvBasedUserHandler() {
  if (env == dev) {
    return MockUserHandler;
  }
  return firebase.auth();
}

export function IsProdEnv() {
  return env == prod;
}

// Handles signing up user for firebase and internal database.
/*
signUpObject = {
    email: string,
    password: string,
    name: string,
    current_position: string,
    affiliation: string,
    is_researcher: string,
}
*/
export function SignUp(signUpObject) {
  let userHandler = EnvBasedUserHandler();
  return userHandler
    .createUserWithEmailAndPassword(signUpObject.email, signUpObject.password)
    .then(async (event) => {
      console.log(event.user);
      if (IsProdEnv()) {
        firebase
          .auth()
          .currentUser.sendEmailVerification()
          .then(() => {
            // Email verification sent!
            // ...
            console.log("Email verification sent!");
          });
      }
      // Register user.
      console.log("User id pushed on login", event.user.uid);
      const user = {
        email: event.user.email,
        id: event.user.uid,
        name: signUpObject.name,
        position: signUpObject.current_position,
        interests: signUpObject.interests,
        affiliation: signUpObject.affiliation,
        is_researcher: signUpObject.is_researcher,
      };
      await CreateUser(user).then((response) => {
        if (!response.ok) {
          console.log(response.statusText);
        } else {
          console.log("Created user in database successfully.");
        }
        return;
      });

      if (!user.is_researcher) {
        const userSkills = {
          id: event.user.uid,
          skills: signUpObject.skills,
        };
        await CreateSkillsEntry(userSkills).then((response) => {
          if (!response.ok) {
            console.log(response.statusText);
          } else {
            console.log("Created skills entry in database successfully.");
          }
          return;
        });
      }
      return event.user;
    });
}

// Handles signing in user for firebase.
/*
signInObject = {
    email: string,
    password: string
}
*/
export function SignIn(signInObject) {
  let userHandler = EnvBasedUserHandler();
  return userHandler
    .signInWithEmailAndPassword(signInObject.email, signInObject.password)
    .then(async (userInfo) => {
      console.log(userInfo);
      try {
        const response = await GetUser(userInfo.user.uid);
        return response;
      } catch (error) {
        console.log(error.message);
      }
    });
}

export function SignOut() {
  let userHandler = EnvBasedUserHandler();
  return userHandler
    .signOut()
    .then()
    .catch((error) => {
      console.log("Failed to log out.");
      console.log(error.message);
    });
}
