// This is manual for now.
// TODO: Store a local map of university of domain and update it

import { prod, env } from "../components/Login/util";

// few days.
const AFFILIATION_DOMAIN_MAP = {
  "University Of Toronto": ".utoronto.ca",
  "Test University": "",
};

export function GetAllowedAffiliations() {
  if (env == prod) {
    delete AFFILIATION_DOMAIN_MAP["Test University"];
  }
  return Object.keys(AFFILIATION_DOMAIN_MAP);
}

// Private: Returns domain for allow listed affiliations only,
// undefined otherwise.
function GetCorrespondingDomain(affiliation) {
  return AFFILIATION_DOMAIN_MAP[affiliation];
}

// Returns true if email domain matches affiliation, otherwise false.
export function IsValidEmailAffiliationPair(affiliation, email) {
  if (!affiliation in AFFILIATION_DOMAIN_MAP) {
    return false;
  }
  if (!email.endsWith(GetCorrespondingDomain(affiliation))) {
    return false;
  }
  return true;
}
