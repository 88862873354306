import React from "react";
import { Dialog, Text } from "evergreen-ui";

export const Confirm = ({
  isShown,
  setIsShown,
  confirmLabel = "Confirm",
  intent = "none",
  title,
  message,
  onConfirm,
}) => {
  return (
    <Dialog
      minHeightContent={0}
      isShown={isShown}
      title={title}
      intent={intent}
      onConfirm={() => onConfirm()}
      onCloseComplete={() => setIsShown(false)}
      confirmLabel={confirmLabel}
    >
      <Text>{message}</Text>
    </Dialog>
  );
};
