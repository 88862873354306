import React from "react";
import { Pane, Text, Icon, SmallCrossIcon } from "evergreen-ui";

export const IconText = (props) => {
  const { iconBefore, color = "#696f8c", fontSize = 12 } = props;

  return (
    <Pane display="flex" alignItems="center">
      <Icon color={color} icon={iconBefore} />
      <Text marginLeft={4} color={color} fontSize={fontSize}>
        {props.children}
      </Text>
    </Pane>
  );
};
