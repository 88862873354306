/*
Defines methods for fetching institution information and updating them. All fetches inserting/updating
/reading opportunities are defined here.
*/

const ROUTE = "/api/institution";

// Fetch a specific institution from its id.
export function GetInstitution(id) {
  return fetch(`${ROUTE}/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      return response.json();
    }
  });
}

// Fetch all institutions.
export async function GetAllInstitutions() {
  return await fetch(`${ROUTE}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      return response.json();
    }
  });
}

// Delete a specific institution from its id.
export function DeleteInstitution(id) {
  return fetch(`${ROUTE}/${id}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" }
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      return response.json();
    }
  });
}

// Add a new institution from its id with name. Returns response with id.
export function CreateInstitution(name) {
  const data = { "institution": name };
  return fetch(`${ROUTE}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      return response.json();
    }
  });
}

// Update a specific institution from its id with name.
export function UpdateInstitution(id, name) {
  const data = { "institution": name };
  return fetch(`${ROUTE}/${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  }).then((response) => {
    if (!response.ok) {
      console.log(response.statusText);
    } else {
      console.log("Response ok");
      return response.json();
    }
  });
}

