import React from "react";
import { Link } from "react-router-dom";
import { Button, ArrowRightIcon, Badge } from "evergreen-ui";
import { color } from "../../themes/default.json";

import hero from "../../assets/landing/landing-hero.svg";
import researchers1 from "../../assets/landing/researchers-1.svg";
import students1 from "../../assets/landing/students-1.svg";

const Landing = () => {
  return (
    <>
      <div className="container margin-t-large">
        <div className="row h-100 align-items-center mt-5">
          <div className="col-md-6">
            <h2>
              Finding a research position
              <br />
              has never been easier
            </h2>
            <h5>
              Sign up today to get access to hundreds <br />
              of researchers and professionals!
            </h5>
            <div className="mt-3">
              <Link to="/register" className="text-decoration-none">
                <Button
                  appearance="primary"
                  iconAfter={ArrowRightIcon}
                  backgroundColor={color.primary}
                >
                  Sign Up
                </Button>
              </Link>
              <Link to="" className="text-decoration-none">
                <Button
                  className="ml-2"
                  appearance="minimal"
                  color={color.primary}
                >
                  Contact Us
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <img src={hero} className="col" />
        </div>
      </div>
      <div className="container margin-t-large">
        <div className="row justify-content-between">
          <div className="col-md-5">
            <Badge color={color.primary}>For Researchers</Badge>
            <h2 className="mt-3">
              Find interested research students seamlessly
            </h2>
            <h5>
              Simply search for profiles and <br />
              connect with interested students
            </h5>
          </div>
          <div className="col-md-6">
            <img src={researchers1} className="col" />
          </div>
        </div>
      </div>
      <div className="container margin-t-large">
        <div className="row justify-content-between">
          <div className="col-md-6"></div>
          <div className="col-md-6 text-right">
            <Badge color="blue">For Students</Badge>
            <h2 className="mt-3">
              Find research opportunities and supervisors with just a click
            </h2>
            <h5>
              Simply search and apply for research opportunities
              <br />
              or post your profile for researchers to see
            </h5>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <img src={students1} className="col" />
      </div>
      <div className="margin-t-large text-center padding-b-large">
        <h2>Get started today</h2>
        <h5>Network with hundreds of researchers instantly</h5>
        <Link to="/register" className="text-decoration-none">
          <Button
            appearance="primary"
            iconAfter={ArrowRightIcon}
            backgroundColor={color.primary}
            className="mt-3"
          >
            Sign Up
          </Button>
        </Link>
      </div>
    </>
  );
};
export default Landing;
